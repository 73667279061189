<template>
    <div class="law-detail">
        <div class="lawDetail-content">
            <div class="lawDetail-top module">
                <p class="title">基本信息</p>
                <p class="name">{{ obj.lawName }}</p>
                <div class="item-list">
                    <div class="item">
                        <p>
                            <span>编号：</span>
                            <span>{{ obj.standardNumber }}</span>
                        </p>
                        <p>
                            <span>状态：</span>
                            <span>{{ lawStatus[obj.status]?.text }}</span>
                        </p>
                    </div>
                    <div class="item">
                        <p>
                            <span>性质：</span>
                            <span>{{ lawNature[obj.nature]?.text }}</span>
                        </p>
                        <p>
                            <span>发布部门/单位：</span>
                            <span>{{ obj.issuingDepartment }}</span>
                        </p>
                    </div>
                    <div class="item">
                        <p>
                            <span>发布日期：</span>
                            <span>{{ $dayjs(obj.releaseDate).format('YYYY年MM月DD日') }}</span>
                        </p>
                        <p>
                            <span>实施日期：</span>
                            <span>{{ $dayjs(obj.implementationDate).format('YYYY年MM月DD日') }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="lawDetail-middle module" v-if="obj.applicableProvisions">
                <p class="title">适用条款</p>
                <p>{{ obj.applicableProvisions }}</p>
            </div>
            <div class="lawDetail-bottom module">
                <p class="title">内容详情</p>
                <div v-html="obj.contentRichText" class="richtext"></div>
            </div>
        </div>
    </div>
</template>
<script>
import {lawStatus,lawNature} from './../kbase.data'
export default{
    data(){
        return{
            lawStatus,
            lawNature,
            obj:{},
        }
    },
    created(){
        this.getDetail();
    },
    methods:{
        getDetail(){
            this.$api.kbase.getLawDetail({
                lawId:this.$route.query.lawId
            }).then(res=>{
                this.obj = res.data;
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.law-detail{
    min-height: 100vh;
    box-sizing: border-box;
    background: #F3F3F3;
    padding-bottom: 24px;
}
.lawDetail-middle{
    p{
        font-size: 18px;
        line-height: 30px;
    }
}
.richtext{
    ::v-deep img{
        max-width: 100%;
    }
}
.lawDetail-content{
    overflow: hidden;
    .module{
        background: #fff;
        border-radius: 8px;
        padding:24px;
        width:1200px;
        margin:24px auto;
    }
    .item-list{
        display: flex;
        justify-content: space-around;
        align-items: center;
        .item{
            p:nth-child(1){
                margin-bottom: 8px;
            }
        }
        .item:nth-child(1){
            width: 318px;
            padding-right: 60px;
            box-sizing: border-box;
        }
        .item:nth-child(3){
            width:375px;
            padding-left: 82px;
            box-sizing: border-box;
        }
        .item:nth-child(2){
            flex-grow: 1;
            padding-left: 90px;
            box-sizing: border-box;
            padding-right: 24px;
            position: relative;
            &::after,&::before{
                position: absolute;
                content:'';
                width: 1px;
                height: 44px;
                background: #E0E0E0;
                top: 50%;
                transform: translateY(-50%);
            }
            &::before{
                left: 0;
            }
            &::after{
                right: 0;
            }
        }
        p{
            font-size: 16px;
            line-height: 24px;
            color: #333;
            display: flex;
        }
        p span:nth-child(1){
            color: #999;
            white-space: nowrap;
        }
    }
    .name{
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 32px;
        color: #333;
    }
    .title{
        font-size: 24px;
        line-height: 24px;
        font-weight: 600;
        color: #333;
        position: relative;
        padding-left: 13px;
        margin-bottom: 30px;
        &::after{
            content: '';
            width: 5px;
            height: 20px;
            background: #E60003;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
</style>